import { render } from "./spinner.vue?vue&type=template&id=13fb5e01"
import script from "./spinner.vue?vue&type=script&lang=js"
export * from "./spinner.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "13fb5e01"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('13fb5e01', script)) {
    api.reload('13fb5e01', script)
  }
  
  module.hot.accept("./spinner.vue?vue&type=template&id=13fb5e01", () => {
    api.rerender('13fb5e01', render)
  })

}

script.__file = "src/loaders/spinner.vue"

export default script