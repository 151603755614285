import { render } from "./mini-spinner.vue?vue&type=template&id=65208feb"
import script from "./mini-spinner.vue?vue&type=script&lang=js"
export * from "./mini-spinner.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "65208feb"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('65208feb', script)) {
    api.reload('65208feb', script)
  }
  
  module.hot.accept("./mini-spinner.vue?vue&type=template&id=65208feb", () => {
    api.rerender('65208feb', render)
  })

}

script.__file = "src/loaders/mini-spinner.vue"

export default script