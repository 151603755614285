import { render } from "./bar-fade.vue?vue&type=template&id=0e5a3884"
import script from "./bar-fade.vue?vue&type=script&lang=js"
export * from "./bar-fade.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0e5a3884"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0e5a3884', script)) {
    api.reload('0e5a3884', script)
  }
  
  module.hot.accept("./bar-fade.vue?vue&type=template&id=0e5a3884", () => {
    api.rerender('0e5a3884', render)
  })

}

script.__file = "src/loaders/bar-fade.vue"

export default script