import { render } from "./bar-fade-scale.vue?vue&type=template&id=2fadb40a"
import script from "./bar-fade-scale.vue?vue&type=script&lang=js"
export * from "./bar-fade-scale.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2fadb40a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2fadb40a', script)) {
    api.reload('2fadb40a', script)
  }
  
  module.hot.accept("./bar-fade-scale.vue?vue&type=template&id=2fadb40a", () => {
    api.rerender('2fadb40a', render)
  })

}

script.__file = "src/loaders/bar-fade-scale.vue"

export default script