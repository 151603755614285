import { render } from "./line-wave.vue?vue&type=template&id=3806209a"
import script from "./line-wave.vue?vue&type=script&lang=js"
export * from "./line-wave.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3806209a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3806209a', script)) {
    api.reload('3806209a', script)
  }
  
  module.hot.accept("./line-wave.vue?vue&type=template&id=3806209a", () => {
    api.rerender('3806209a', render)
  })

}

script.__file = "src/loaders/line-wave.vue"

export default script