import { render } from "./ring.vue?vue&type=template&id=22afae10"
import script from "./ring.vue?vue&type=script&lang=js"
export * from "./ring.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "22afae10"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('22afae10', script)) {
    api.reload('22afae10', script)
  }
  
  module.hot.accept("./ring.vue?vue&type=template&id=22afae10", () => {
    api.rerender('22afae10', render)
  })

}

script.__file = "src/loaders/ring.vue"

export default script